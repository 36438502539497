import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/Layout/layout"
// import Image from "../components/image"
import SEO from "../components/seo"
import HomePage from "../components/HomePage/HomePage"

import "../scss/custom.scss"

const IndexPage = () => {
  return (
    <div style={{overflowX:'hidden'}}>
      <Layout>
        <SEO title="Home" />
        <HomePage />

      </Layout>
    </div>
  )
}

export default IndexPage
