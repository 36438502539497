import React from "react"
import {
  Col,
  Container,
  Row,
  Button
} from 'reactstrap';

import Select from 'react-select';
import HeroSlider from '../HeroSlider/HeroSlider'
import CarDecks from '../CarDecks/CarDecks'
import PartsDecks from '../PartsDecks/PartsDecks'

const HomePage = class extends React.Component {

  // const [qty, setQty] = useState(options[0]);
  constructor(props) {
    super(props);
    this.state = {
      qty: { value: 1, label: '1' },
      collection: { value: "sku_GiKHyid8OsFTS4", label: "速遞 - 香港境內" }
    }

  }

  render() {

    return (
      <>
        <HeroSlider />
        <Container className="homepage-container container-fluid">
          <Row>
            <Col xs="12" md="12" className="text-center">
              <h2>至 HIT 車款</h2>
              <CarDecks />
              <h2>汽車零件及裝飾</h2>
              <PartsDecks />
            </Col>
          </Row>
        </Container>
      </>
    )
  }
}


export default HomePage
