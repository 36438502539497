import React from 'react';
import {
  Card, Button, CardImg, CardTitle, CardBody,
  Col, Row
} from 'reactstrap';
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"


const CarDecks = (props) => {

  const cars = useStaticQuery(graphql`
    query allcarsQuery {
      allCarsJson {
        edges {
          node {
            made
            color
            id
            model
            price
            type
            image
            stripeTestSKU
            stripeProdSKU
          }
        }
      }
    }
  `)

  return (
    <div>
      <Row className="row-cols-2 row-cols-md-3 px-2">
        {
          cars.allCarsJson.edges.map(({ node, index }) => {
            const car = node
            console.log(node);
            return (
              <Col className="mb-4" key={index}>
                <Card className="carCards">
                  <CardImg top className="p-4 carImg" src={require(`../../images/cars/${car.image}`)} alt="Card image cap" />
                  <CardBody>
                    <CardTitle className="carTitle ">{car.made} {car.model}</CardTitle>
                    <Row className="">
                      <Col xs="12" sm="6">
                        <div className="carPrice"> HK$ {Number(car.price).toLocaleString()}
                          <span className="perDay"> / 每天</span>
                        </div>
                      </Col>
                      <Col xs="12" sm="6" className="button-holder">
                        <Link to={`/cars/${car.made}-${car.model}/`}>
                          <Button className="bookingBtn">立即預約</Button>
                        </Link>
                      </Col>
                    </Row>
                    {/*<CardSubtitle className="carColor">Color: {car.color}</CardSubtitle>*/}
                    {/*<CardText>This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</CardText>*/}

                  </CardBody>
                </Card>
              </Col>
            )
          })
        }


        {/*<Card>
          <CardImg top width="100%" src="/assets/256x186.svg" alt="Card image cap" />
          <CardBody>
            <CardTitle>Card title</CardTitle>
            <CardSubtitle>Card subtitle</CardSubtitle>
            <CardText>This card has supporting text below as a natural lead-in to additional content.</CardText>
            <Button>Button</Button>
          </CardBody>
        </Card>
        <Card>
          <CardImg top width="100%" src="/assets/256x186.svg" alt="Card image cap" />
          <CardBody>
            <CardTitle>Card title</CardTitle>
            <CardSubtitle>Card subtitle</CardSubtitle>
            <CardText>This is a wider card with supporting text below as a natural lead-in to additional content. This card has even longer content than the first to show that equal height action.</CardText>
            <Button>Button</Button>
          </CardBody>
        </Card>*/}
      </Row>
    </div>
  );
};

export default CarDecks;
