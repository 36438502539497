import React, { useState } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption
} from 'reactstrap';

import banner1 from '../../images/banner/banner13.jpeg'
import banner2 from '../../images/banner/banner5.jpeg'
import banner3 from '../../images/banner/banner9.jpeg'

const items = [
  {
    src: banner3,
    altText: 'Cove共享汽車服務',
    caption: 'Cove共享汽車服務',
    subCaption: '全新面貌 全新體驗'
  },
  {
    src: banner2,
    altText: '靈活日租 無需養車',
    caption: '靈活日租 無需養車',
    subCaption: '隨你喜歡 選擇心水車款'
  },
  {
    src: banner1,
    altText: '簡便網上預約',
    caption: '簡便網上預約',
    subCaption: '一家大細 準備出發'
  },
]

const HeroSlider = (props)=>{
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  }

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  }

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  }

  const slides = items.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
      >
        <img className="carouselImage" src={item.src} alt={item.altText} />
        <CarouselCaption captionText={item.subCaption} captionHeader={item.caption} />
      </CarouselItem>
    );
  });

  return (
    <Carousel
      activeIndex={activeIndex}
      next={next}
      previous={previous}
    >
      <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} />
      {slides}
      {/*<CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
      <CarouselControl direction="next" directionText="Next" onClickHandler={next} />*/}
    </Carousel>
  );
}

export default HeroSlider
